.fieldset {
  border: none;

  legend {
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
  }
}
