@import '../../../styles/common';

.block {
  margin-bottom: 20px;
}

.logTable {
  thead {
    th {
      padding: 5px 3px;
      &:first-child {
        padding-left: 13px;
      }
      &:last-child {
        padding-right: 13px;
      }
      select {
        padding: 0;
      }
    }
    th:nth-child(3) {
      select {
        max-width: 125px;
        padding-left: 0;
      }
    }
    th:nth-child(4) {
      select {
        max-width: 150px;
        word-wrap: break-word;
        white-space: normal;
      }
    }
    th:nth-child(6) {
      select {
        max-width: 100px;
        word-wrap: break-word;
        white-space: normal;
      }
    }
  }
}
